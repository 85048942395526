@font-face {
  font-family: "Avenir-Black";
  src: url("./assets/AvenirLTStd-Black.otf");
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  /* font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", Verdana, Geneva, Tahoma, sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  line-height: 100%;
}
