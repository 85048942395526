.progress {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #ddd;
  margin-top: 2%;
}

.progress > .progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #0a91bf;
  transition: width 0.5s ease;
}
